<template>
  <div class="about">
    <p>フラッシュマップは地名を<ruby>単語帳<rp>(</rp><rt>フラッシュカード</rt><rp>)</rp></ruby>のように覚えるためのウェブ・アプリです。</p>
    <p>画面をタップ（またはクリック）することで、地図表示されている場所の地名が表示されるので、単語帳を使うときのように覚えていきましょう。「覚えた」「覚えていない」を選択することで、覚えていない地名から優先的に覚えていくことができます。</p>
    <p>漫然と取り組むよりも答えを確認する前に頭の中でしっかり解答を思い浮かべるようにするとより効果的です（テスト効果）。</p>
    <p style="text-align:right; margin:-1em 0;"><img src="/img/anki_card.png" style="width:3em;"></p>
    <p>インストール不要ですぐに使えます。</p>
    <hr>
  </div>
</template>
<style scoped>
.about{
  text-align:left;
  font-size: 1.5rem;
}
.about p{
  text-indent: 1em;
  margin:1em 0;
}
</style>