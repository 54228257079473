import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "main" }
const _hoisted_2 = { id: "footer" }
const _hoisted_3 = { style: {"position":"absolute","width":"100%","height":"100%","background":"rgba(0,0,0,.5)"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HierarchicalList = _resolveComponent("HierarchicalList")!
  const _component_scroll_with_hint = _resolveComponent("scroll-with-hint")!
  const _component_toast_notify = _resolveComponent("toast-notify")!
  const _component_loading_effect = _resolveComponent("loading-effect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["page", {homeComing:_ctx.homeComing}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_scroll_with_hint, {
          style: {"height":"100%"},
          maxWidth: 500
        }, {
          default: _withCtx(() => [
            _createVNode(_component_HierarchicalList, {
              list: _ctx.list,
              onMapSelected: _ctx.showLoading
            }, null, 8, ["list", "onMapSelected"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, [
            _createElementVNode("small", null, "map.napple.xyz ver " + _toDisplayString(_ctx.app_ver), 1)
          ])
        ]),
        _createVNode(_component_toast_notify, {
          message: "学習したい地図を選んでください。",
          ref: "hintToast"
        }, null, 512),
        _createVNode(_component_toast_notify, {
          message: "データを読込中です。しばらくお待ち下さい。",
          ref: "loadingToast",
          lifespan: Infinity
        }, null, 512)
      ])
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_loading_effect)
    ], 512), [
      [_vShow, _ctx.loading]
    ])
  ], 64))
}