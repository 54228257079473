import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toast-overlay", _ctx.fadeClass]),
    ref: "overlay",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.overlayClick && _ctx.overlayClick(...args)), ["stop"])),
    style: _normalizeStyle(_ctx.overlayCSS)
  }, [
    _createElementVNode("div", {
      class: "toast-wrapper",
      ref: "wrapper"
    }, _toDisplayString(_ctx.message), 513)
  ], 6))
}