
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
})

export default class SettingPanel extends Vue {
  get darkMode(){
    return this.$store.state.darkMode;
  }
  set darkMode(val){
    this.$store.commit('setDarkMode',val);
  }
  get colorlessMap(){
    return !this.$store.state.colorfulMap;
  }
  set colorlessMap(val){
    this.$store.commit('setColorfulMap',!val);
  }
  get autoAnswer(){
    return this.$store.state.autoAnswer;
  }
  set autoAnswer(val){
    this.$store.commit('setAutoAnswer',val);
  }
  get thinkingTime(){
    return this.$store.state.thinkingTime;
  }
  set thinkingTime(val){
    this.$store.commit('setThinkingTime',val);
  }
  get showKanji(){
    return this.$store.state.showKanji;
  }
  set showKanji(val){
    this.$store.commit('setShowKanji',val);
  }

}
