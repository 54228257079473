
import { ref } from "@vue/reactivity";
import { Options, Vue } from 'vue-class-component';
import { Watch} from 'vue-property-decorator'
import HeaderMenu from '@/components/HeaderMenu.vue'; // @ is an alias to /src

@Options({
  props: {
  },
  components: {
    HeaderMenu
  },
})

export default class App extends Vue {

  get isHome(){
    return this.$route.fullPath=="/";
  }

  get cssVariants(){
    if(!this.$store.state.darkMode){
      return {
        "--baseBgColor":"#ffffff",
        "--baseFontColor":"#000000",
        "--grayFontColor":"#aaaaaa",

        "--buttonBgColor":"#dddddd",
        "--buttonHoverBgColor":"#eeeeee",

        "--transparentBgColor":"#ffffff99",
        "--transparentAntiBgColor":"#00000011",
        "--shadowColor":"#00000088",
        "--linkColor":"#5555ff",
      }
    }else{
      return {
        "--baseBgColor":"#222222",
        "--baseFontColor":"#dddddd",
        "--grayFontColor":"#aaaaaa",

        "--buttonBgColor":"#444444",
        "--buttonHoverBgColor":"#333333",

        "--transparentBgColor":"#22222299",
        "--transparentAntiBgColor":"#ffffff11",
        "--shadowColor":"#44444488",
        "--linkColor":"#7777ff",
      }
    }
  }
}
