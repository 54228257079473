import { nextTick } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [{
  path: '/',
  name: 'Home',
  meta:{
    title:'フラッシュマップ',
    description:'暗記カードのように地図上で地名を学習するためのアプリです。',
  },
  component: Home
},
{
  path: '/nations',
  name: 'nations',
  meta:{
    title:'世界の国・地域',
    description:'世界の国・地域を地図上で学習できます。',
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/UsNations.vue')
},{
  path: '/chomei_test',
  name: 'chomei_test',
  meta:{
    title:'町名テスト',
    description:'町名テスト',
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/ChomeiTest.vue')
},{
  path: '/jp_prefectures',
  name: 'jp_prefectures',
  meta:{
    title:'日本の都道府県',
    description:'日本の都道府県を地図上で学習できます。'
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/JpPrefectures.vue')
},{
  path: '/jp_kuni',
  name: 'jp_kuni',
  meta:{
    title:'日本の旧国名',
    description:'日本の旧国名を地図上で学習できます。'
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/JpKuni.vue')
}];

const prefs:({name:string,id:string})[] = [{"name":"北海道","id":"01"},{"name":"青森県","id":"02"},{"name":"岩手県","id":"03"},{"name":"宮城県","id":"04"},{"name":"秋田県","id":"05"},{"name":"山形県","id":"06"},{"name":"福島県","id":"07"},{"name":"茨城県","id":"08"},{"name":"栃木県","id":"09"},{"name":"群馬県","id":"10"},{"name":"埼玉県","id":"11"},{"name":"千葉県","id":"12"},{"name":"東京都","id":"13"},{"name":"神奈川県","id":"14"},{"name":"新潟県","id":"15"},{"name":"富山県","id":"16"},{"name":"石川県","id":"17"},{"name":"福井県","id":"18"},{"name":"山梨県","id":"19"},{"name":"長野県","id":"20"},{"name":"岐阜県","id":"21"},{"name":"静岡県","id":"22"},{"name":"愛知県","id":"23"},{"name":"三重県","id":"24"},{"name":"滋賀県","id":"25"},{"name":"京都府","id":"26"},{"name":"大阪府","id":"27"},{"name":"兵庫県","id":"28"},{"name":"奈良県","id":"29"},{"name":"和歌山県","id":"30"},{"name":"鳥取県","id":"31"},{"name":"島根県","id":"32"},{"name":"岡山県","id":"33"},{"name":"広島県","id":"34"},{"name":"山口県","id":"35"},{"name":"徳島県","id":"36"},{"name":"香川県","id":"37"},{"name":"愛媛県","id":"38"},{"name":"高知県","id":"39"},{"name":"福岡県","id":"40"},{"name":"佐賀県","id":"41"},{"name":"長崎県","id":"42"},{"name":"熊本県","id":"43"},{"name":"大分県","id":"44"},{"name":"宮崎県","id":"45"},{"name":"鹿児島県","id":"46"},{"name":"沖縄県","id":"47"}];

prefs.forEach((pref) => {
  const shikuchoson = pref.id=="13"?"市区町村":"市町村";
  routes.push({
    path: '/jp_shikuchoson/'+pref.id,
    name:'jp_shikuchoson_'+pref.id,
    meta:{
      title:`${pref.name}の${shikuchoson}`,
      description:`${pref.name}の${shikuchoson}を地図上で学習できます。`,
    },
    component: () => import('../views/JpShikuchoson.vue'),
    props: { prefCode: pref.id }
  });
});

routes.push(...[
  { path: '/globe', redirect: '/nations' },
  { path: '/jp', redirect: '/jp_prefectures' },
  {
    path: '/test_ruby',
    name:'test_ruby',
    meta:{
      title:'test',
    },
    component: () => import('../views/TestRuby.vue') },
  {
    path: '/:foo',
    name:'404',
    meta:{
      title:'フラッシュマップ',
    },
    component: () => import('../views/NotFound.vue') }
]);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from,next)=>{
  (router as any)["referrer"]=from;

  document.title = typeof to.meta.title=="string"?to.meta.title:"";
  const description =  typeof to.meta.description=="string"?to.meta.description:"";
  (document as any).querySelector("meta[name='description']").setAttribute('content', description)


  next();
})

export default router;
