import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e04662a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_hierarchical_list = _resolveComponent("hierarchical-list", true)!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.id
      }, [
        (item.link)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: item.link,
              onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$emit('mapSelected');})
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : _createCommentVNode("", true),
        (item.subList)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: ($event: any) => (item.isOpen=!item.isOpen)
            }, [
              _createTextVNode(_toDisplayString(item.name) + " ", 1),
              (item.isOpen)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "∧"))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, "∨"))
            ], 8, _hoisted_1))
          : _createCommentVNode("", true),
        _createVNode(_Transition, { name: "sub" }, {
          default: _withCtx(() => [
            (item.subList)
              ? _withDirectives((_openBlock(), _createBlock(_component_hierarchical_list, {
                  key: 0,
                  list: item.subList,
                  onMapSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('mapSelected')))
                }, null, 8, ["list"])), [
                  [_vShow, item.isOpen]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ]))
}