
import { Options, Vue } from 'vue-class-component';

@Options({
  props: ["maxWidth"],
  components: {
  },
})

export default class ScrollWithHint extends Vue {
  maxWidth!:number;
  contentsInner!:HTMLElement;
  contentsWrapper!:HTMLElement;
  contentsHeight=0;
  marginLeft=0;

  scrollState = {
    contentsBelow:false,
    contentsAbove:false,
  }

  mounted(){
    this.contentsInner = this.$refs.contentsInner as HTMLElement;
    this.contentsWrapper = this.$el as HTMLElement;
    this.changeScrollState();
    window.addEventListener("resize",this.changeScrollState);
  }

  changeScrollState(){
    const ch = this.contentsInner.clientHeight;
    this.contentsHeight = ch;
    const sh = this.contentsInner.scrollHeight;
    const st = this.contentsInner.scrollTop;
    
    if(sh<=ch){
      this.scrollState = {
        contentsBelow:false,
        contentsAbove:false,
      }
    }else{
      if(st==0){
        this.scrollState =  {
          contentsBelow:true,
          contentsAbove:false,
        }
      }else if((sh-ch)==st){
        this.scrollState =  {
          contentsBelow:false,
          contentsAbove:true,
        }
      }else{
        this.scrollState =  {
          contentsBelow:true,
          contentsAbove:true,
        }
      }
    }


    try{
      const outerWidth = this.contentsWrapper.clientWidth;
      this.marginLeft = outerWidth>this.maxWidth ? (outerWidth-this.maxWidth)/2 : 0;
    }catch(e){void(0)}
  }
  changeScrollStateDelay(){
    setTimeout(()=>this.changeScrollState(),550);
  }
}
