
import { Options, Vue } from 'vue-class-component';
import HierarchicalList from '@/components/HierarchicalList.vue'; // @ is an alias to /src
import ToastNotify from '@/components/ToastNotify.vue'; // @ is an alias to /src
import ScrollWithHint from '@/components/ScrollWithHint.vue'; // @ is an alias to /src
import LoadingEffect from '@/components/LoadingEffect.vue';

@Options({
  props: {
  },
  components: {
    HierarchicalList,
    ToastNotify,
    ScrollWithHint,
    LoadingEffect,
  },
})

export default class Home extends Vue {
  prefectures=[{"name":"北海道","id":"01"},{"name":"青森県","id":"02"},{"name":"岩手県","id":"03"},{"name":"宮城県","id":"04"},{"name":"秋田県","id":"05"},{"name":"山形県","id":"06"},{"name":"福島県","id":"07"},{"name":"茨城県","id":"08"},{"name":"栃木県","id":"09"},{"name":"群馬県","id":"10"},{"name":"埼玉県","id":"11"},{"name":"千葉県","id":"12"},{"name":"東京都","id":"13"},{"name":"神奈川県","id":"14"},{"name":"新潟県","id":"15"},{"name":"富山県","id":"16"},{"name":"石川県","id":"17"},{"name":"福井県","id":"18"},{"name":"山梨県","id":"19"},{"name":"長野県","id":"20"},{"name":"岐阜県","id":"21"},{"name":"静岡県","id":"22"},{"name":"愛知県","id":"23"},{"name":"三重県","id":"24"},{"name":"滋賀県","id":"25"},{"name":"京都府","id":"26"},{"name":"大阪府","id":"27"},{"name":"兵庫県","id":"28"},{"name":"奈良県","id":"29"},{"name":"和歌山県","id":"30"},{"name":"鳥取県","id":"31"},{"name":"島根県","id":"32"},{"name":"岡山県","id":"33"},{"name":"広島県","id":"34"},{"name":"山口県","id":"35"},{"name":"徳島県","id":"36"},{"name":"香川県","id":"37"},{"name":"愛媛県","id":"38"},{"name":"高知県","id":"39"},{"name":"福岡県","id":"40"},{"name":"佐賀県","id":"41"},{"name":"長崎県","id":"42"},{"name":"熊本県","id":"43"},{"name":"大分県","id":"44"},{"name":"宮崎県","id":"45"},{"name":"鹿児島県","id":"46"},{"name":"沖縄県","id":"47"}];
  list=[{
    name:"都道府県",
    link:"/jp_prefectures",
  },{
    name:"世界の国・地域",
    link:"/nations",
  },{
    name:"日本の旧国名",
    link:"/jp_kuni",
  },{
    name:"市区町村 （都道府県別）",
    subList:this.prefectures.map(pre=>({
      name:pre.name,
      link:"/jp_shikuchoson/"+pre.id
    }))
  }];
  hintToast!:ToastNotify;
  hintTimerId!:number;

  homeComing=false;
  loading=false;
  app_ver = process.env.VUE_APP_PWA_VER;
  mounted(){
    this.hintToast = this.$refs.hintToast as ToastNotify;
    this.hintTimerId = setTimeout(()=>this.hintToast.open(),15*1000);

    const referrer = (this.$router as any)["referrer"];
    if(referrer.name!=undefined && referrer.name!="Home"){
      this.homeComing=true;
    }
  }
  showLoading(){
    clearTimeout(this.hintTimerId);

    const loadingToast = this.$refs.loadingToast as ToastNotify;
    setTimeout(()=>loadingToast.open(),1000);
    
    this.loading=true;
  }
}
