import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";
import 'leaflet/dist/leaflet.css'

createApp(App).use(VueGtag, {
    config: {
        id: process.env.VUE_APP_GA_ID,
        params: {
            darkMode: store.state.darkMode,
            colorfulMap: store.state.colorfulMap,
            autoAnswer: store.state.autoAnswer,
            thinkingTime: store.state.thinkingTime,
            PWA_VER: process.env.VUE_APP_PWA_VER,
        }
    }
},router).use(store).use(router).mount('#app')