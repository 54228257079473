
import { Options, Vue } from 'vue-class-component';

@Options({
  props: ["list"],
  components: {
  },
})

export default class HierarchicalList extends Vue {
  list!:any[];
}
