import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMenu = _resolveComponent("HeaderMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.cssVariants),
    class: "app-root"
  }, [
    _createVNode(_component_HeaderMenu, { isHome: _ctx.isHome }, null, 8, ["isHome"]),
    _createVNode(_component_router_view, { style: {"position":"relative","z-index":"0"} })
  ], 4))
}