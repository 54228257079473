
import { Options, Vue } from 'vue-class-component';

@Options({
  props:["message","lifespan","zIndex"]
})
export default class ToastNotify extends Vue {
  toastWrapper!:HTMLElement;
  toastOverlay!:HTMLElement;
  message!:string;
  lifespan!:number;
  _lifespan!:number;
  status:("close"|"opening"|"open"|"closing")="close";

  duration=500;//ms
  zIndex!:number;
  _zIndex!:number;

  lifespanTimerId!:number;

  created(){
    this._lifespan = this.lifespan || 5000;
    this._zIndex = this.zIndex || 50;
  }
  mounted(){
    this.toastWrapper = this.$refs.wrapper as HTMLElement;
    this.toastOverlay = this.$el as HTMLElement;
  }
  get overlayCSS(){
    return {
      "--toast-duration":this.duration/1000+"s",
      "z-index":this._zIndex,
    }
  }
  open(){
    clearTimeout(this.lifespanTimerId);
    
    if(this.status=="close" || this.status=="closing"){
      this.status = "opening";
      setTimeout(()=>this.status = "open",this.duration);
    }
    if(this.lifespan!=Infinity){
      this.lifespanTimerId = setTimeout(()=>this.close(),this.duration+this._lifespan);
    }
  }
  close(){
    if(this.status=="open" || this.status=="opening"){
      this.status = "closing";
      setTimeout(()=>this.status = "close",this.duration);
    }
  }
  
  get fadeClass(){
    return {
      "close":this.status=="close",
      "opening":this.status=="opening",
      "open":this.status=="open",
      "closing":this.status=="closing",
    }
  }
  overlayClick(){
    this.close();
  }
}
