import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { event } from 'vue-gtag'

export default createStore({
  state():any{
    return {
      darkMode: false,
      colorfulMap: true,
      autoAnswer: false,
      thinkingTime: 10,
      firstVisit: true,
      showKanji: false,
    }
  },
  mutations: {
    setDarkMode (state:any, value:boolean) {
      state.darkMode = value;
      event('setting_change', {
        setting_item: "darkMode",
        setting_value: value,
        event_category: "setting_change",
        event_label: "darkMode_setting",
      });
    },
    setColorfulMap (state:any, value:boolean) {
      state.colorfulMap = value;
      event('setting_change', {
        setting_item: "colorfulMap",
        setting_value: value,
        event_category: "setting_change",
        event_label: "colorfulMap_setting",
      });
    },
    setAutoAnswer (state:any, value:boolean) {
      state.autoAnswer = value;
      event('setting_change', {
        setting_item: "autoAnswer",
        setting_value: value,
        event_category: "setting_change",
        event_label: "autoAnswer_setting",
      });
    },
    setThinkingTime (state:any, value:number) {
      state.thinkingTime = Math.min(30,Math.max(5,value));
      event('setting_change', {
        setting_item: "thinkingTime",
        setting_value: value,
        event_category: "setting_change",
        event_label: "thinkingTime_setting",
      });
    },

    setShowKanji (state:any, value:boolean) {
      state.showKanji = value;
      event('setting_change', {
        setting_item: "showKanji",
        setting_value: value,
        event_category: "setting_change",
        event_label: "showKanji_setting",
      });
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
