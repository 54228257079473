
import { Options, Vue } from 'vue-class-component';

@Options({
  props:{
    title:String,
  }

})
export default class SlideUpPanel extends Vue {
  isOpen=false;
  title!:string;
  duration=500;//ms
  status:("close"|"opening"|"open"|"closing")="close";

  open(){
    this.status = "opening";
    setTimeout(()=>this.status = "open",this.duration);
  }
  close(){
    this.status = "closing";
    setTimeout(()=>this.status = "close",this.duration);
  }
  get animationClass(){
    return {
      "close":this.status=="close",
      "opening":this.status=="opening",
      "open":this.status=="open",
      "closing":this.status=="closing",
    }
  }
}
