import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["contents-wrapper", {noScroll:!_ctx.scrollState.contentsAbove && !_ctx.scrollState.contentsBelow}])
  }, [
    _createElementVNode("div", {
      class: "contents-inner",
      ref: "contentsInner",
      onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeScrollState && _ctx.changeScrollState(...args)))
    }, [
      _createElementVNode("div", {
        class: "contents-area",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeScrollStateDelay && _ctx.changeScrollStateDelay(...args))),
        style: _normalizeStyle({'max-width':_ctx.maxWidth+'px','margin-left':_ctx.marginLeft+'px'})
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 4)
    ], 544),
    _createElementVNode("div", {
      class: "contents-shadow-wrapper",
      style: _normalizeStyle({height:_ctx.contentsHeight+'px'})
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["contents-shadow", _ctx.scrollState])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["contents-shadow-above", {contentsAbove:_ctx.scrollState.contentsAbove}])
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(["contents-shadow-below", {contentsBelow:_ctx.scrollState.contentsBelow}])
        }, null, 2)
      ], 2)
    ], 4)
  ], 2))
}