
import { Options, Vue } from 'vue-class-component';
import SlideUpPanel from '@/components/SlideUpPanel.vue'; // @ is an alias to /src
import AboutPanel from '@/views/AboutPanel.vue'; // @ is an alias to /src
import SettingPanel from '@/views/SettingPanel.vue'; // @ is an alias to /src
import { event } from 'vue-gtag'


@Options({
  props: {
    isHome:Boolean,
  },
  components: {
    SlideUpPanel,
    AboutPanel,
    SettingPanel,
  },
})

export default class HeaderMenu extends Vue {

  settingPanel!:SlideUpPanel;
  aboutPanel!:SlideUpPanel;
  
  mounted(){
    this.settingPanel = this.$refs.settingPanel as SlideUpPanel;
    this.aboutPanel = this.$refs.aboutPanel as SlideUpPanel;
  }
  openSetting(){
    this.settingPanel.open();
    event('openSetting', {
      event_category: "menu_open",
      event_label: "open_setting"
    });
  }
  openAbout(){
    this.aboutPanel.open();
    event('openAbout', {
      event_category: "menu_open",
      event_label: "open_about"
    });
  }
  get title(){
    return this.$route.meta.title;
  }
}
